import { createContext, useContext } from 'react';
import { Field } from './utils';

interface ContextProps {
  nextContactValues: number;
  colorList: Field[];
}

const defaultContext: ContextProps = {
  // @ts-ignore
  nextContactValues: 0,
  colorList: [],
};

export const TimeRangeContext = createContext<ContextProps>(defaultContext);

export const useTimeRangeContext = () => useContext(TimeRangeContext);
