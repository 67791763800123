import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { css } from '@emotion/css';
import { SatelliteData, dividerStyle, infoBoxStyle, nameToMne, nowLineStyle, satelliteBarStyle, satelliteNameStyle, satelliteRowStyle, timeLabelStyle1, timeMarkStyle, timelineStyle } from './utils';
import { useTimeSetting } from './TimeSettingContext';
import { dateConfig } from 'constant/dateConfig';

type TimeLineProps = {
  data: Record<string, SatelliteData>;
};

const TimeLine: React.FC<TimeLineProps> = ({ data }) => {
  const [visibleInfoPops, setVisibleInfoPops] = useState<Record<string, boolean>>({});
  //const [currentTime, setCurrentTime] = useState(moment())
  const [currentTime, setCurrentTime] = useState(moment.utc())
  const [hoverPosition, setHoverPosition] = useState<number | null>(null);
  const [isOnBar, setIsOnBar] = useState<boolean>(false);

  const { timeSetting } = useTimeSetting(); // Access timeSetting here

  const config = useMemo(() => dateConfig[timeSetting], [timeSetting]);
  const { lengthTime, lengthUnit, lengthCount, intervalTime, intervalUnit, previosTime, previosUnit } = config;

  useEffect(() => {
    const timer = setInterval(() => setCurrentTime(moment().utc()), 30000) // Update  every 30s  
    return () => clearInterval(timer)
  }, [])

  const generateTimeLabels = useCallback(() => {
    const labels = []
    const startTime = currentTime.clone().subtract(previosTime, previosUnit).startOf(intervalUnit)
    for (let i = 0; i <= parseInt(lengthCount, 10); i++) {
      labels.push(startTime.clone().add(i * parseInt(intervalTime, 10), intervalUnit))
    }
    return labels
  }, [currentTime, previosTime, previosUnit, intervalUnit, lengthCount, intervalTime])

  const timeLabels = useMemo(() => generateTimeLabels(), [generateTimeLabels])

  const nowLinePosition = useMemo(() => {
    const startTime = timeLabels[0]
    const totalMinutes = moment.duration(lengthTime, lengthUnit).asMinutes()
    const currentMinutes = currentTime.diff(startTime, 'minutes')
    
    return `${(currentMinutes / totalMinutes) * 100}%`
    //return `calc(${(currentMinutes / totalMinutes) * 100}% + 100px)`
    //return `calc(100px + ${(currentMinutes / totalMinutes) * 100}%)`
  }, [currentTime, timeLabels, lengthTime, lengthUnit])

  const timelineStart = useMemo(() => currentTime.clone().subtract(previosTime, previosUnit).startOf(intervalUnit), [currentTime, previosTime, previosUnit, intervalUnit]);
  const timelineEnd = useMemo(() => currentTime.clone().add(parseInt(lengthTime, 10), lengthUnit).startOf(intervalUnit), [currentTime, lengthTime, lengthUnit, intervalUnit]);
  // Calculate the total duration of the timeline in seconds
  const totalDuration = timelineEnd.diff(timelineStart, 'seconds');

  const calculateBarPosition = (tAos: number, tLos: number) => {

    if (moment.unix(tAos) > timelineEnd) {
      return null;
    }

    const aosSeconds = moment.unix(tAos).diff(timelineStart, 'seconds');
    const losSeconds = moment.unix(tLos).diff(timelineStart, 'seconds');

    const leftPercentage = (aosSeconds / totalDuration) * 100;
    const widthPercentage = ((losSeconds - aosSeconds) / totalDuration) * 100;

    return { leftPercentage, widthPercentage };
  };

  const handleValueChartClick = (key: string) => {
    setVisibleInfoPops(prev => ({ 
      ...prev,
      [key]: !prev[key]
    }));
  };

  // Function to calculate gaps
  const calculateGaps = () => {
    let allPasses: Array<{ start: number; end: number }> = [];

    Object.values(data).forEach(satelliteData => {
      const tAos_list = satelliteData.tAos_Value.split(',').map(Number);
      const tLos_list = satelliteData.tLos_Value.split(',').map(Number);

      tAos_list.forEach((tAos, index) => {
        allPasses.push({ start: tAos, end: tLos_list[index] });
      });
    });

    // Sort passes by start time
    allPasses.sort((a, b) => a.start - b.start);

    // Merge overlapping passes
    const mergedPasses = allPasses.reduce((acc, curr) => {
      if (acc.length === 0) {
        return [curr];
      }
      const last = acc[acc.length - 1];
      if (curr.start <= last.end) {
        last.end = Math.max(last.end, curr.end);
      } else {
        acc.push(curr);
      }
      return acc;
    }, [] as Array<{ start: number; end: number }>);

    // Calculate gaps
    const gaps: Array<{ start: number; end: number }> = [];

    const timelineStartUnix = timelineStart.unix();
    const timelineEndUnix = timelineEnd.unix();

    if (mergedPasses[0].start > timelineStartUnix) {
      gaps.push({ start: timelineStartUnix, end: mergedPasses[0].start });
    }

    for (let i = 0; i < mergedPasses.length - 1; i++) {
      gaps.push({ start: mergedPasses[i].end, end: mergedPasses[i + 1].start });
    }

    if (mergedPasses[mergedPasses.length - 1].end < timelineEndUnix) {
      gaps.push({ start: mergedPasses[mergedPasses.length - 1].end, end: timelineEndUnix });
    }

    return gaps;
  };

  const gaps = useMemo(() => calculateGaps(), [calculateGaps]);

  const handleMouseMove = (e: React.MouseEvent) => {
    // Get the relative position of the mouse inside the timeline container
    if (!isOnBar) {
      return;
    }

    const boundingRect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - boundingRect.left; // Get X relative to the container
    setHoverPosition(x);
  };

  const handleMouseLeave = () => {
    if (!isOnBar) {
      return;
    }

    setHoverPosition(null);
  };

  return (
    <div className={timelineStyle} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>

      <div className={css`
        display:flex;
        `}>
          

        <div className={css`width:100px`}>
          

        </div>
        <div className={timeLabelStyle1}>

          {timeLabels.map((time, index, array) => {

            const showDate = index === 0 || time.date() !== array[index - 1].date();

            return (
              <div key={`${time.format()}-${timeSetting}`}>
                {showDate && (
                  <span
                    className={css`
                position: absolute;
                left: ${(index / parseInt(lengthCount, 10)) * 100}%;
                transform: translateX(-50%);
                top: -20px;
                font-size: 0.8em;
                color: #666;
              `}>
                    {time.format('DDD')}
                    {/* {time.format('MMM DD')} */}
                  </span>
                )}
                <span
                  className={css`
                  position: absolute;
                  left: ${(index / parseInt(lengthCount, 10)) * 100}%;
                  transform: translateX(-50%);
                `}>
                  {time.format('HH:mm')}
                </span>
                <div
                  className={timeMarkStyle}
                  style={{ left: `${(index / parseInt(lengthCount, 10)) * 100}%` }}
                />
                
              </div>
            );
          })}
          <div className={nowLineStyle} style={{ left: nowLinePosition }} />
        </div>
        
      </div>
      {Object.entries(data).map(([satellite, satelliteData], rowIndex) => {
        const tAos_list = satelliteData.tAos_Value.split(',').map(Number);
        const tLos_list = satelliteData.tLos_Value.split(',').map(Number);
        const station_list = satelliteData.station_Value.split(',');
        const trcode_list = satelliteData.trCode_Value.split(',');

        return (
          <div key={satellite} className={satelliteRowStyle}>
            <div className={satelliteNameStyle}>{satellite}</div>
            <div className={satelliteBarStyle}>
              {tAos_list.map((tAos, index) => {
                const tLos = tLos_list[index];
                const station = station_list[index];
                const trCode = trcode_list[index];

                const position = calculateBarPosition(tAos, tLos);

                // Only render if the position is valid (i.e., tAos is within 24 hours)
                if (position === null) {
                  return null;
                }

                const { leftPercentage, widthPercentage } = position;

                return (
                  <div
                    key={index}
                    className={css`
                      position: absolute;
                      left: ${leftPercentage}%;
                      width: ${widthPercentage}%;
                      height: 100%;
                      background-color: ${nameToMne[station]};
                      cursor: pointer;
                    `}
                    onClick={() => handleValueChartClick(`${satellite}-${index}`)}
                    onMouseMove={() => setIsOnBar(true)}
                    onMouseLeave={() => setIsOnBar(false)}
                  >
                    <div>
                      {visibleInfoPops[`${satellite}-${index}`] && (
                        <div className={infoBoxStyle}>
                          <p>AOS: {moment.unix(tAos).utc().format('YYYY-MM-DD HH:mm:ss [UTC]')}</p>
                          {/* <p>AOS: {moment.unix(tAos).format('YYYY-MM-DD HH:mm:ss')}</p> */}
                          <p>LOS: {moment.unix(tLos).utc().format('YYYY-MM-DD HH:mm:ss [UTC]')}</p>
                          <p>TR Code: {trCode}</p>
                          <p>Station: {station}</p>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            {rowIndex < Object.entries(data).length && <div className={dividerStyle} />}
          </div>
        );
      })}

      

      {/* Gap Row */}
      <div className={satelliteRowStyle}>
        
        <div className={satelliteNameStyle}>Idle Time</div>
        
        <div className={satelliteBarStyle}>
          
          {gaps.map((gap, index) => {
            const position = calculateBarPosition(gap.start, gap.end);
            if (position === null) {
              return null;
            }
            const { leftPercentage, widthPercentage } = position;

            return (
              <div
                key={index}
                className={css`
                  position: absolute;
                  left: ${leftPercentage}%;
                  width: ${widthPercentage}%;
                  height: 100%;
                  background-color: gray; // Gray color for gaps
                  cursor: pointer;
                  
                    `}
                onClick={() => handleValueChartClick(`Gaps-${index}`)}
                onMouseMove={() => setIsOnBar(true)}
                onMouseLeave={() => setIsOnBar(false)}
              >
                <div>
                  {visibleInfoPops[`Gaps-${index}`] && (
                    <div className={infoBoxStyle}>
                      <p>AOS: {moment.unix(gap.start).utc().format('YYYY-MM-DD HH:mm:ss [UTC]')}</p>
                      <p>LOS: {moment.unix(gap.end).utc().format('YYYY-MM-DD HH:mm:ss [UTC]')}</p>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div className={dividerStyle} />
      </div>

      {/* Line at mouse hover */}
      {hoverPosition !== null && isOnBar && (
        <div
          className={css`
            position: absolute;
            top: 0;
            left: ${hoverPosition}px; // Mouse position in pixels
            width: 2px;
            height: 100%;
            background-color: red;
            pointer-events: none;
          `}
        />
      )}

    </div>
  );
};

export default TimeLine;
