import { css } from "@emotion/css";

export type Field = {
  name: string;
  type: string;
  labels?: { [key: string]: string };
  values: any[]; // Replace 'any' with a more specific type if possibl
};

export type SatelliteData = {
  tAos_Value: string;
  tLos_Value: string;
  trCode_Value: string;
  station_Value: string;
};

// Styles
export const reactAppWrapper = css`
  font-family: Arial, sans-serif;
  color: white;
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #1e1e1e;
  height: 100%;
`;

export const timeSettingStyle = css`
  display: flex;
  gap: 10px;
`;

export const buttonStyle = css`
  background-color: #333;
  border: none;
  color: white;
  padding: 5px 10px;
  cursor: cursor;
  &:hover {
    background-color: #555;
  }
`;

export const timeInfoStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const legendStyle = css`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
`;

export const legendItemStyle = css`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const timeLabelStyle = css`
  display: flex; 
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  font-size: 17px;
`;

export const timelineStyle = css`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 100%;
  background-color: #1e1e1e;
  color: white;
  position: relative;
`;

export const satelliteRowStyle = css`
  display: flex;
  height: 30px;
  position: relative;
  margin-bottom: 1px; // Add a small margin to separate rows
`;

export const satelliteNameStyle = css`
  width: 100px;
  text-align: right;
  padding-right: 10px;
  
`;

export const dividerStyle = css`
position: absolute;
left: 100px; // Align with the start of the bar
right: 0;
bottom: 0;
height: 1px;
background-color: #444;
`;

export const satelliteBarStyle = css`
  height: 20px;
  flex-grow: 1;
  position: relative;
`;

export const infoBoxStyle = css`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  border: 1px solid #4a4a4a;
  color: white;
  padding: 10px;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(255, 255, 255, 0.2);
  pointer-events: none;
  transform: translateY(-100%);
  font-size: 12px;
  width: 300px;
`;

// Styles remain the same as before, only including changed or new styles
export const timeLabelStyle1 = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: #888;
  font-size: 12px;
  position: relative;
  background-color: #2a2a2a;
  padding: 5px 0;
  height: 30px; 
`

export const timeMarkStyle = css`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #444;
`

export const nowLineStyle = css`
  position: absolute;
  top: 0;
  height: 315px;
  width: 2px;
  background-color: #00ff00;
  z-index: 1;
`;

//assign random color to each telemetry
export const nameToMne: { [key: string]: string } = {
  "USNHI_13M1": "rgb(255, 0, 0)",
  "WLP_LEOT" : "rgb(0, 255, 0)",
  "BGS_11M" : "rgb(0, 0, 255)",
  "WLP_11M" : "rgb(255, 255, 0)",
  "MLD_10M2" : "rgb(255, 0, 255)",
  "WHS_18M1" : "rgb(0, 255, 255)",
  "AGO_13M" : "rgb(218, 204, 204)",
  "SNG_9M1" : "rgb(128, 128, 128)",
  "USNHI_13M2" : "rgb(128, 0, 0)",
  "USNAU_13M1" : "rgb(128, 128, 0)",
  "DSS_34" : "rgb(0, 128, 128)",
  "DSS_45" : "rgb(0, 128, 0)",
  "TDRS_6" : "rgb(77, 99, 128)",
  "DSS_54" : "rgb(128, 0, 128)",
  "TDRS_9" : "rgb(166, 144, 50)",
  "AGO_9M" : "rgb(0, 255, 128)",
  "DSS_65" : "rgb(0, 128, 255)",
  "DSS_63" : "rgb(128, 0, 255)",
  "TDRS_11" : "rgb(128, 128, 255)",
  "DSS_14" : "rgb(103, 194, 176)",
  "DSS_15" : "rgb(255, 128, 128)",
  "TDRS_5" : "rgb(158,32,95)",
  "WHM_9M" : "rgb(123,234,123)",
  "TDRS_3" : "rgb(64,32,4)",
  "TDRS_7" : "rgb(124,32,4)",
  "DSS_27" : "rgb(86,58,110)",
  "DSS_24" : "rgb(135,32,4)",
  "DSS_43" : "rgb(231,90,44)",
  "TDRS_10" : "rgb(203,240,129)",
  "WHM_15M2" : "rgb(23,132,4)",
  "MLD_10M1" : "rgb(203,32,144)",

  // random colors
  "WHM_15M1" : "rgb(23,32,144)",
  "TDRS_12" : "rgb(203,112,4)",
  "USNSA_12M1" : "rgb(203,132,94)",
  "SNG_9M" : "rgb(217,216,169)",
  "DSS_36" : "rgb(23,132,84)",
  "DSS_26" : "rgb(203,172,44)",
  "AGO_12M" : "rgb(103,32,4)",
  "USNSA_10M1" : "rgb(103,132,4)",
  "DSS_56" : "rgb(103,52,4)",
  "MLD_13M1" : "rgb(224,79,115)",
  "DSS_35" : "rgb(235,167,221)",
  "DSS_25" : "rgb(121,186,145)",
  "DSS_55" : "rgb(243,132,4)",
  "DSS_53" : "rgb(109,186,209)",
}

// SELECT 
//     GROUP_CONCAT(station ORDER BY tAos) AS station_list, 
//     scName,
//     GROUP_CONCAT(tAos ORDER BY tAos) AS tAos_list, 
//     GROUP_CONCAT(tLos ORDER BY tAos) AS tLos_list, 
//     GROUP_CONCAT(trCode ORDER BY tAos) AS trCode_list
// FROM 
//     bdps.operational_schedule 
// WHERE 
//     tLos > UNIX_TIMESTAMP()
// GROUP BY 
//     scName
// ORDER BY 
//     MIN(tLos) ASC;

export function findColorForStation(station: string, colorList: Field[]){
  const stationFull = colorList.find(column => column.name === "Stations (Full)")
  const stationShort = colorList.find(column => column.name === "Stations (Short)")
  const colorColumn = colorList.find(column => column.name === "Colors")

  // Ensure all required columns are found
  if (!stationFull || !stationShort || !colorColumn) {
    console.error("One or more required columns are missing");
    return null;
  }

  // Loop through the values of the full and short station names
  for (let i = 0; i < stationFull.values.length; i++) {
    if (stationFull.values[i] === station || stationShort.values[i] === station) {
      return colorColumn.values[i]; // Return the corresponding color
    }
  }

  // If no match is found, return null
  return null;
}
