type TimeKeys = '3 hrs' | '12 hrs' | '1 day' | '3 days' | '7 days';

type MomentUnits = 'second' | 'minute' | 'hour' | 'day' | 'week' | 'month' | 'year';

export const dateConfig: { [key in TimeKeys]: {
    lengthUnit: MomentUnits; 
    lengthTime: string; 
    lengthCount: string;
    intervalTime: string;
    intervalUnit: MomentUnits;
    previosTime: string; 
    previosUnit: MomentUnits; } } = {
    '3 hrs': {
        'lengthTime': '3',
        'lengthUnit': 'hour',
        'lengthCount': '13',
        'intervalTime': '15',
        'intervalUnit': 'minute',
        'previosTime': '15',
        'previosUnit': 'minute',
    },
    '12 hrs': {
        'lengthTime': '12',
        'lengthUnit': 'hour',
        'lengthCount': '25',
        'intervalTime': '30',
        'intervalUnit': 'minute',
        'previosTime': '30',
        'previosUnit': 'minute',
    },
    '1 day': {
        'lengthTime': '1',
        'lengthUnit': 'day',
        'lengthCount': '25',
        'intervalTime': '1',
        'intervalUnit': 'hour',
        'previosTime': '1',
        'previosUnit': 'hour',
    },
    '3 days': {
        'lengthTime': '3',
        'lengthUnit': 'day',
        'lengthCount': '13',
        'intervalTime': '6',
        'intervalUnit': 'hour',
        'previosTime': '6',
        'previosUnit': 'hour',
    },
    '7 days': {
        'lengthTime': '7',
        'lengthUnit': 'day',
        'lengthCount': '15',
        'intervalTime': '12',
        'intervalUnit': 'hour',
        'previosTime': '12',
        'previosUnit': 'hour',
    },
}