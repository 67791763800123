import React, { createContext, useContext, useState } from 'react';

export type TimeKeys = '3 hrs' | '12 hrs' | '1 day' | '3 days' | '7 days';

// Define the context structure
interface TimeSettingContextType {
  timeSetting: TimeKeys;
  setTimeSetting: (time: TimeKeys) => void;
}

// Create context with a default value
const TimeSettingContext = createContext<TimeSettingContextType | undefined>(undefined);

// Create a provider component
export const TimeSettingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [timeSetting, setTimeSetting] = useState<TimeKeys>('1 day');

  return (
    <TimeSettingContext.Provider value={{ timeSetting, setTimeSetting }}>
      {children}
    </TimeSettingContext.Provider>
  );
};

// Custom hook to use the TimeSettingContext
export const useTimeSetting = () => {
  const context = useContext(TimeSettingContext);
  if (!context) {
    throw new Error('useTimeSetting must be used within a TimeSettingProvider');
  }
  return context;
};
