import { createContext, useContext } from 'react';

interface ContextProps {
  nextContactValues: number;
}

const defaultContext: ContextProps = {
  // @ts-ignore
  timeRange: { from: new Date(), to: new Date() },

  nextContactValues: 0,
};

export const TimeRangeContext = createContext<ContextProps>(defaultContext);

export const useTimeRangeContext = () => useContext(TimeRangeContext);
