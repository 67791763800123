import React, { useCallback, useState } from 'react'
import { Field } from './utils';
import { css } from '@emotion/css';
import TimeLine from './TimeLine';
import Background from './Background';
import { TimeSettingProvider } from './TimeSettingContext';

type AppProps = {
  dbData: Field[];
  width: number;
  height: number;
  source: string;
  // ... other props if there are any
};

const reactAppWrapper = css`

  @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
  
    position: relative;
    background-color: black;
    color: #FFFFFF;
    font-size: 15px;
    font-family: monospace;
    box-sizing: border-box;
    text-align: center;
    overflow: hidden;
  `;

type TelemetryData = {
  station_Value: string; // Replace 'any' with the actual type of cnvValue
  tAos_Value: string; // Replace 'any' with the actual type of unit
  tLos_Value: string;
  trCode_Value: string;
};

const App: React.FC<AppProps> = ({ dbData, source, width, height }) => {

  const [selectedTimeRange, setSelectedTimeRange] = useState<[moment.Moment, moment.Moment] | null>(null);
  const [zoomRange, setZoomRange] = useState<[moment.Moment, moment.Moment] | null>(null);

  // Function to create a dictionary from dbData
  const createTelemetryDictionary = useCallback(() => {
    const telemetryDict: Record<string, TelemetryData> = {};

    const scNameColumn = dbData.find(column => column.name === "scName")
    const stationListColumn = dbData.find(column => column.name === "station_list")
    const tAosListColumn = dbData.find(column => column.name === "tAos_list")
    const tLosListColumn = dbData.find(column => column.name === "tLos_list")
    const trCodeListColumn = dbData.find(column => column.name === "trCode_list")

    if (!scNameColumn || !scNameColumn.values) {
      console.error("scName column not found or has no values");
      return telemetryDict;
    }

    scNameColumn.values.forEach((scName, index) => {
      const station_Value = stationListColumn ? stationListColumn.values[index] : '';
      const tAos_Value = tAosListColumn ? tAosListColumn.values[index] : '';
      const tLos_Value = tLosListColumn ? tLosListColumn.values[index] : '';
      const trCode_Value = trCodeListColumn ? trCodeListColumn.values[index] : '';

      telemetryDict[scName] = {
        tAos_Value,
        tLos_Value,
        trCode_Value,
        station_Value
      };
    });

    return telemetryDict;
  }, [dbData]);

  const telemetryDictionary = createTelemetryDictionary();

  // Callback function to handle selected time range
  const handleTimeRangeChange = (start: moment.Moment, end: moment.Moment) => {
    setSelectedTimeRange([start, end]);
    console.log('Selected Time Range:', start, end); // Debug log
  };

  return (
    <div className={reactAppWrapper} style={{ height, width }}>
      <TimeSettingProvider>
        <Background data={telemetryDictionary} selectedTimeRange={selectedTimeRange} onTimeRangeChange={handleTimeRangeChange} setZoomRange={setZoomRange} zoomRange={zoomRange}>
          <TimeLine data={telemetryDictionary} selectedTimeRange={selectedTimeRange} onTimeRangeChange={handleTimeRangeChange} setZoomRange={setZoomRange} zoomRange={zoomRange}/>
        </Background>
      </TimeSettingProvider>

    </div>
  )
}

export default App
