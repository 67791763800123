import React, { useEffect, useMemo, useState } from 'react'
import { SatelliteData, buttonStyle, legendItemStyle, legendStyle, nameToMne, reactAppWrapper, timeInfoStyle, timeLabelStyle, timeSettingStyle } from './utils';
import { css, cx } from '@emotion/css';
import moment from 'moment';
import { useTimeRangeContext } from './TimeRangeContext';
import { useTimeSetting, TimeKeys } from './TimeSettingContext';
import { dateConfig } from 'constant/dateConfig';
import 'moment-timezone';

interface BackgroundProps {
  children: React.ReactNode;
  data: Record<string, SatelliteData>;
}

const formatTime = (time: moment.Moment) => {
  return {
    utc: time.utc().format('DDD HH:mm:ss [UTC]'),
    pacific: time.tz('America/Los_Angeles').format('YYYY-MM-DD HH:mm:ss')
  };
};

const Background: React.FC<BackgroundProps> = ({ children, data }) => {
  //const [timeSetting, setTimeSetting] = useState('1 day');
  const [currentTime, setCurrentTime] = useState(moment().utc());
  const { timeSetting, setTimeSetting } = useTimeSetting(); // Use the context hook here

  // New state variables
  const [currentlyContacted, setCurrentlyContacted] = useState<string[]>([]);
  const [nextContactTime, setNextContactTime] = useState<moment.Moment | null>(null);

  const { nextContactValues } = useTimeRangeContext();

  const config = useMemo(() => dateConfig[timeSetting], [timeSetting]);
  const { lengthTime, lengthUnit } = config; 

  useEffect(() => {
    const timer = setInterval(() => setCurrentTime(moment().utc()), 1000) // Update every minute
    return () => clearInterval(timer)
  }, [])

  const stationsWithinTimeRange = useMemo(() => {
    const now = moment();
    let nextContact: moment.Moment | null = null;
    const currentContacts: string[] = [];

    const result = Object.entries(data).map(([satellite, satelliteData]) => {
      const tAos_list = satelliteData.tAos_Value.split(',').map(Number);
      const station_list = satelliteData.station_Value.split(',');

      const filteredStations = tAos_list.reduce<Array<{ station: string; tAosTime: string; timeUntilContact: number; }>>((acc, tAos, index) => {
        const station = station_list[index];
        const tAosTime = moment(tAos * 1000);
        const timeUntilContact = tAosTime.diff(now, 'milliseconds') / moment.duration(1, lengthUnit).asMilliseconds();

        if (timeUntilContact <= parseFloat(lengthTime)) {
          // Check if this station is currently being contacted
          if (timeUntilContact <= 0 && timeUntilContact > -5) { // Assuming a contact lasts 5 minutes
            currentContacts.push(`${satellite} @ ${station}`);
          }

          // Update next contact time if this is the soonest future contact
          if (timeUntilContact > 0 && (!nextContact || tAosTime.isBefore(nextContact))) {
            nextContact = tAosTime;
          }

          acc.push({
            station,
            tAosTime: tAosTime.toISOString(),
            timeUntilContact,
          });
        }

        return acc;
      }, []);

      return {
        satellite,
        filteredStations,
      };
    });

    // Update state variables
    setCurrentlyContacted(currentContacts);
    setNextContactTime(nextContact);

    return result;
  }, [data, lengthTime, lengthUnit]);

  // Get a unique set of all stations within the next 24 hours
  const activeStationsSet = new Set(
    
    stationsWithinTimeRange.flatMap(({ filteredStations }) =>
      filteredStations.map(({ station }) => station)
    )
  );

  // Convert the set back to an array for rendering
  const activeStations = Array.from(activeStationsSet);

  // Get all stations
  const [showAllStations, setShowAllStations] = useState(false);

  const allStations = Object.keys(nameToMne);

  const toggleShowAllStations = () => {
    setShowAllStations(!showAllStations);
  };

  const getNextContactClass = () => {
    const nextContact = moment(nextContactValues * 1000);
    const minutesUntilContact = nextContact.diff(currentTime, 'minutes');

    if (minutesUntilContact <= 10) {
      return "red";
    } else if (minutesUntilContact <= 30) {
      return "orange";
    } else if (minutesUntilContact <= 60) {
      return "yellow";
    }
    return '';
  };

  const contactStyle = css`
  color: ${getNextContactClass()};
`;

  const time = ['3 hrs', '12 hrs', '1 day', '3 days', '7 days'] as TimeKeys[];

  const currentTimeFormatted = formatTime(currentTime);
  //const nextContactFormatted = formatTime(moment(nextContactValues * 1000));

  return (
    <div className={reactAppWrapper}>
      <div className={timeInfoStyle}>
        <div className={timeSettingStyle}>
          {time.map((setting) => (
            <button
              key={setting}
              className={buttonStyle}
              style={{ backgroundColor: timeSetting === setting ? '#555' : '#333' }}
              onClick={() => setTimeSetting(setting)}

            >
              {setting}
            </button>
          ))}
        </div>

        <div>
          <div className={cx(timeLabelStyle, css`
          align-items: flex-start`)}>
            <span>Current Contact:</span>
            {currentlyContacted.length === 0 ? 
            <div>
              - @ -
            </div> :
            <div className={css`
            display: flex;
            flex-direction: column;
            `}>
              {currentlyContacted.map((station) => (
                <span key={station}>{station}</span>
              ))}
            </div>
            }
          </div>
        </div>

        <div>
          <div className={timeLabelStyle}>
            <span>Current Time:</span>
            <span>{currentTimeFormatted.utc}</span>
          </div>
          <div className={timeLabelStyle}>
            <span>Next Contact:</span>
            <span className={contactStyle}>{nextContactTime ? nextContactTime.utc().format('DDD HH:mm:ss [UTC]') : ''}</span>
            {/* <span className={contactStyle}>{nextContactFormatted.utc}</span> */}
            { /* ALso display Pacific Time */}
          </div>
        </div>
      </div>
      {children}
      <div className={legendStyle}>
        {(showAllStations ? allStations : activeStations)
          .sort((a, b) => a.localeCompare(b))
          .map((station) => (
            <div key={station} className={legendItemStyle}>
              <div style={{ width: 20, height: 20, backgroundColor: nameToMne[station] }} />
              <span>{station}</span>
            </div>
          ))}
        <div className={legendItemStyle} onClick={toggleShowAllStations} style={{ cursor: 'pointer' }}>
          <span>&rsaquo;&rsaquo;&rsaquo;</span>
        </div>
      </div>

    </div>
  );
};

export default Background
